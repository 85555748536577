import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")

const StaticDetail = ({ data }, props) => {
  const PageData = data?.strapiPage
  return (
    <Layout dark popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top" />
      <div className="pattern-bg">
        <BreadcrumbModule
          subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
          subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
          parentlabel={
            PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title
          }
          parentlink={
            PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug
          }
          title={PageData.title}
        />

        {PageData?.add_page_modules?.length > 0 &&
          PageData.add_page_modules?.map((module, index) => {
            return (
              <div className="static-page">
                <Container>
                  <Row>
                    <Col xl={7}>
                      <h1>{module.title}</h1>
                      {module.strapi_component ===
                        "page-modules.plain-content" && (
                        <div>
                          <ContentModule
                            Content={module.content?.data?.content}
                          />
                          {/* <StaticShare /> */}
                        </div>
                      )}
                    </Col>
                    <Col xl={1} />
                    <Col xl={4} className="d-xl-block d-none" />
                  </Row>
                </Container>
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    />
  )
}

export default StaticDetail

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          id
          content {
            data {
              content
            }
          }
          title
          strapi_component
        }
      }
    }
  }
`
